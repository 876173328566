import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { PrioritizedAgendaHomeTile } from "./pages/home-page-tiles/PrioritizedAgendaHomeTile"; // <-- This must be the main app of your application - Should be the same as in your index.tsx

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "common-components";
import { getOktaAuthConfig } from "./global/okta-config";
import AxiosProvider from "api/axios-context";

import "./index.scss";
import AuthenticationWrapper from "AuthenticationWrapper";
import { PermissionsProvider } from "global/permissions";
import RuntimeSettingsWrapper from "RuntimeSettingsWrapper";
import { ThemeProvider } from "@opsdti-global-component-library/amgen-design-system";

window.paVersion = () => {
  // eslint-disable-next-line no-console
  console.log(`VERSION: [${window.__RUNTIME_CONFIG__.REACT_APP_VERSION}]`);
};

let styleElements: Element[] | null = null;

const TileApp = () => {
  const navigate = useNavigate();

  const oktaAuth = useMemo(() => {
    const auth = new OktaAuth(getOktaAuthConfig());

    //used to fix incorrect console warning: https://github.com/okta/okta-react/issues/227
    auth.options.restoreOriginalUri = undefined;

    return auth;
  }, []);

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: "always",
        staleTime: Infinity
      }
    }
  });

  useEffect(() => {
    if (styleElements) {
      document.head.append(...styleElements);
    }

    return () => {
      if (!styleElements) {
        styleElements = [
          ...(document.getElementsByClassName(
            "amgen-rtsensing-prioritized-agenda"
          ) as unknown as Element[])
        ];
      }
      if (styleElements) {
        for (let i = 0; i < styleElements.length; i++) {
          styleElements[i].remove();
        }
      }
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider themeService={null}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AxiosProvider>
            <QueryClientProvider client={queryClient}>
              <AuthenticationWrapper>
                <PermissionsProvider>
                  <Routes>
                    <Route path="*" element={<PrioritizedAgendaHomeTile />} />
                  </Routes>
                  <ReactQueryDevtools />
                </PermissionsProvider>
              </AuthenticationWrapper>
            </QueryClientProvider>
          </AxiosProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Security>
  );
};

const TileIndex = () => {
  return (
    <React.StrictMode>
      <RuntimeSettingsWrapper>
        <BrowserRouter>
          <TileApp />
        </BrowserRouter>
      </RuntimeSettingsWrapper>
    </React.StrictMode>
  );
};

export default TileIndex;
